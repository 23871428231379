import React, { useState } from 'react';
import { Link } from "gatsby"
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  {faAngleRight} from '@fortawesome/free-solid-svg-icons';

const DropdownSubItems = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    const toggle = () => setDropdownOpen(prevState => !prevState);
  
    return (
        <div className="title-nav ">
                <DropdownItem className="d-flex tog-section">
                  <Link to={props.url} className="nav-link dropdown-link">{props.title}</Link> 
                  <div className="">
                  <FontAwesomeIcon className="nav-item-icon"
                    icon={faAngleRight}
                    color="#01717d"
                    size="lg"
                />
                  </div>
                </DropdownItem>
                
           
            <div className="item-nav">
                {props.children}
            </div>
        </div>


    //   <Dropdown isOpen={dropdownOpen} direction="right" toggle={toggle} className="dropdown-item tog-section">
    //     <DropdownToggle caret  className="desctop-tog-btn  nav-link  dropdown-link w-100">
    //       {/* <div className="nav-link dropdown-link"></div> */}
    //       {props.title}
    //       </DropdownToggle>
    //     <DropdownMenu>
    //       {props.children}
    //     </DropdownMenu>
    //   </Dropdown>
    );
  }
  
  export default DropdownSubItems;