import React from 'react';
import { Link } from "gatsby";
import DropdownMenuMobile from "./dropdown-menu-mobile";
import DropdownMenuDesctop from "./dropdown-menu-desctop";
import {DropdownItem} from 'reactstrap';


export default class SubMenuToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isDesctop: true
  }

   componentDidMount() {
     if(window.screen.width < 768) {
       this.setState({isDesctop: false})
     }
   }
  render() {
    const {isDesctop} = this.state;
    const {subItemsList, title, url} = this.props;

    const subItems = subItemsList.map((itemProps, i) => {
     return (
      <DropdownItem key={i}>
        <Link to={itemProps.url} className="nav-link dropdown-link">{itemProps.title}</Link> 
      </DropdownItem>
     )
    })

    const deviceComponent = isDesctop?
                    <DropdownMenuDesctop title={title} url={url}>
                      {subItems}
                    </DropdownMenuDesctop>:
                    <DropdownMenuMobile title={title} url={url}>
                      {subItems}
                    </DropdownMenuMobile>

    return (
      <>
        {deviceComponent}
      </>
    )
  }
}
