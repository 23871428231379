import { Link } from "gatsby"
import React from "react"
import {
  Container
} from 'reactstrap';
import './top-menu.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone} from '@fortawesome/free-solid-svg-icons';
import {  faVk, faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons';



const TopBar = (props) => {
    return (
      <>
      <div className="d-none d-md-block sticky-top w-100">
      <Container fluid className="d-flex justify-content-between top-bar">
            <div className="d-flex m-2">
                <div className="mr-3">
                    <Link target="_blank" to="https://www.instagram.com/plemcentre/" className="phone-style top-menu-icon-style">
                    <FontAwesomeIcon
                icon={faInstagram}
                color="#fff"
                size="lg"
                /></Link>
                </div>
                <div className="mr-3">
                    <Link target="_blank" to="https://vk.com/plemcentre" className="phone-style top-menu-icon-style">
                    <FontAwesomeIcon
                icon={faVk}
                color="#fff"
                size="lg" 
                /></Link>
                </div>
                <div className="mr-5">
                    <Link target="_blank" to="https://www.facebook.com/plemcentre/" className="phone-style top-menu-icon-style">
                    <FontAwesomeIcon
                icon={faFacebook}
                color="#fff"
                size="lg" 
                /></Link>
                </div>
            </div>
            <div className="phone-section d-flex mt-2">
                <div >
                    <a rel="nofollow" className="nav-phone" href="tel:+79814572668 ">
                    <FontAwesomeIcon
                    icon={faPhone}
                    color="#c5ad70"
                    size="lg"
                    className="ml-2"
                />  +7(981)4572668 
                </a>
                </div>
            </div> 
      </Container>
      </div>
      </>
    )
  }
  export default TopBar;