import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {Link} from 'gatsby';
import './footer.css';
import SaltCavesLogo from '../../images/saltCavesLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faVk, faInstagram} from '@fortawesome/free-brands-svg-icons';


const Footer = (props) => {
  return (
    <Container fluid className="footer-bg font-white text-center">
      <Row xs="1" sm="2" md="3">
        <Col className="mt-3">
            <span className="separator-fullScreen mt-1 mb-3 d-md-none"></span>
            <h4 className="footer-title">Petersdorf</h4>
            {/* <img src={SaltCavesLogo} className="logo-style" title="" alt=""/> */}
            <span className="separator-fullScreen mt-1 mb-3 d-none d-md-block"></span>
            <ul>
            <li className="font-gold">ООО «Золотая осень»</li>
            <li className="font-gold">ИНН: 3904602639</li>
            <li className="font-gold">КПП: 390601001</li>
            <li className="font-gold">ОГРН: 1083925022345</li>
            <li className="font-gold">Банк: ОСБ № 8626 </li>
            </ul>
        </Col>
        <Col className="mt-3">
            <span className="separator-fullScreen mt-1 mb-3 d-md-none"></span>
            <h4 className="footer-title">Услуги</h4>
            <span className="separator-fullScreen mt-1 mb-3 d-none d-md-block"></span>
           
            <div className="">
            <Link to='/prodazha-plemennyh-zhivotnyh/prodazha-koz' className="nav-link dropdown-link" 
                  alt="Продажа коз" 
                  title="Продажа коз племенных коз">Продажа коз</Link>
              <Link to='/prodazha-plemennyh-zhivotnyh/prodazha-ovets' className="nav-link dropdown-link" 
                    alt="Продажа овец" 
                    title="Продажа племенных овец">Продажа овец</Link>
            </div>
        </Col>
        <Col className="mt-3">
            <span className="separator-fullScreen mt-1 mb-3 d-md-none"></span>
            <h4 className="footer-title">Навигация</h4>
            <span className="separator-fullScreen mt-1 mb-3 d-none d-md-block"></span>
            <ul className="list-group">
                <li><Link to='/about' className="font-gold">О нас</Link></li>
                <li><Link to="/contacts" className="font-gold"> Контакты</Link></li>
            </ul>
        </Col>
      </Row>
      <hr className="my-2" />
      <Row>
          <Col>
            <p className="font-gold">{new Date().getFullYear()}</p>
          </Col>
      </Row>
    </Container>
  );
}

export default Footer;