import React from 'react';
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  {faAngleDown} from '@fortawesome/free-solid-svg-icons';

export default class TextToggle extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fadeIn: false
    }
    this.onToggle = this.onToggle.bind(this);
  }
  onToggle(e) {
    e.preventDefault();
    this.setState({fadeIn: !this.state.fadeIn})
  }

render() {
  const {fadeIn} = this.state;
  const {title, url} = this.props;
  return(
    <>
    <div className="tog-section">
      <div className="dropdown-item">
      <Link to={url} className="nav-link dropdown-link">{title}</Link>
      </div>
    
    <button onClick={this.onToggle} className="toggle-button-more">
      <FontAwesomeIcon className={!fadeIn?"icon-down mr-2":"icon-up mr-2"}
                    icon={faAngleDown}
                    color="#01717d"
                    size="lg"
                />
      </button>
    </div>
    <div className={fadeIn?"d-block item-block-bg":"d-none"}>
          <div className="ml-2">
            {this.props.children}
          </div>
    </div>
    
  </>
  )
}
}
TextToggle.propTypes = {
  children: PropTypes.node.isRequired,
}

