import { Link } from "gatsby"
import React, { useState, } from "react"
import SubMenuToggle from './sub-menu-toggle'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import './top-menu.css';
import TopBar from './top-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faBars} from '@fortawesome/free-solid-svg-icons';
import {  faVk, faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons';
import LogoPetersdorf from '../../images/Petersdorf.svg';


const TopMenu = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  
  return (
    <>
      <div className="top-nav-selector">
        <TopBar />
        <Navbar expand="md" className={isOpen?"bgIsToggle main-menu":"main-menu"}>
          
          <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon
              icon={faBars}
              //color="#fff"
              size="lg" 
              />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-center nav-bar-border">
            <Nav className="mr-auto" navbar className="justify-content-center">
            <NavItem>
                <Link to='/about/' className="nav-link link-navigation">О НАС</Link>
            </NavItem>
            <UncontrolledDropdown nav inNavbar >
                <DropdownToggle nav caret className="link-navigation">
                  ПРОДАЖА ЖИВОТНЫХ
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem>
                  <Link to='/prodazha-plemennyh-zhivotnyh/prodazha-koz' className="nav-link dropdown-link" 
                  alt="Продажа коз" 
                  title="Продажа коз племенных коз">Продажа коз</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to='/prodazha-plemennyh-zhivotnyh/prodazha-ovets' className="nav-link dropdown-link" 
                    alt="Продажа овец" 
                    title="Продажа племенных овец">Продажа овец</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavbarBrand href="/" as="/" className="logo-box ml-3 mr-3 d-none d-md-block">
                  <img src={LogoPetersdorf} className="logo-style position-absolute" title="Art Stroy Logo" alt="Art Stroy Logo"/>
              </NavbarBrand>
              
              <UncontrolledDropdown nav inNavbar >
                <DropdownToggle nav caret className="link-navigation">
                  ПОРОДЫ КОЗ
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem>
                  <Link to='/porody-koz/alpine_poroda_koz' className="nav-link dropdown-link" 
                  alt="Альпийские козы" 
                  title="Информация о альпийской породе">Альпийская</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to='/porody-koz/burskaya_poroda_koz' className="nav-link dropdown-link" 
                    alt="Бурские козы" 
                    title="Информация о Бурской козе">Бурская</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to='/porody-koz/toggenburgskaya_poroda_koz' className="nav-link dropdown-link"
                    alt="Тоггенбургские козы" 
                    title="Информация о Тоггенбургской козе">Тоггенбургская</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to='/porody-koz/zaanenskaya_poroda_koz' className="nav-link dropdown-link"
                    alt="Зааненские козы"
                    title="Информация о Зааненской козе">Зааненская</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to='/porody-koz/nubiskaya_poroda_koz' className="nav-link dropdown-link" 
                    alt="Нубийские козы" 
                    title="Информация о Нубийской козе">Нубийская</Link> 
                  </DropdownItem>
                {/* { <SubMenuToggle title={'Утепление и герметизация'}
                                url={'/germetizatsiya-derevyannogo-doma/'}
                                subItemsList={[{title:"Герметизация сруба", url: "/germetizatsiya/germetizatsiya-sruba"},
                                                {title:"Конопатка сруба", url: "/germetizatsiya/konopatka-sruba"}]}/>} */}
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar >
                <DropdownToggle nav caret className="link-navigation">
                  ПОРОДЫ ОВЕЦ
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem>
                  <Link to='/porody-ovets/dorper_poroda_ovec' className="nav-link dropdown-link" 
                  alt="Допер овцы" 
                  title="Информация о породе Допер">Дорпер</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to='/porody-ovets/texel_poroda_ovec' className="nav-link dropdown-link" 
                    alt="Тексель овцы" 
                    title="Информация о породе Тексель">Тексель</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to='/porody-ovets/frizskaya_poroda_ovec' className="nav-link dropdown-link"
                    alt="Восточно Фризские овцы" 
                    title="Информация о Восточно Фризской породе">Восточно Фризская</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                  <Link to='/contacts' className="nav-link link-navigation">КОНТАКТЫ</Link>
                  </NavItem>
                  <NavItem className="d-none d-xs-inline ml-md-5 pl-md-5">
                  <Link to="" className="phone-style">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      color="#d2379b"
                      size="lg"
                  /></Link>
              </NavItem>
              <NavItem className="d-none">
                  <Link to="https://vk.com/public198504099">
                    <FontAwesomeIcon
                      icon={faVk}
                      color="#4998ec"
                      size="lg" 
                  /></Link>
                  </NavItem>
                  <NavItem className="d-none">
                  <Link to="https://www.facebook.com/groups/854905041913784">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      color="#4998ec"
                      size="lg" 
                  /></Link>
              </NavItem>
            </Nav>
          
          </Collapse>
        </Navbar>
      </div>
      </>
  );
}

export default TopMenu;
