/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import TopMenu from "./top-menu/top-menu"
import Footer from './footer/footer'
import "./layout.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import { YMInitializer } from 'react-yandex-metrika';






const Layout = ({ children }) => {
/*   const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `) */


  return (
    <>
    <Helmet
    script={[
      //{"src": "//code.jivosite.com/widget/WwcpAM9eAb", "type": "text/javascript"},
    ]}>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
<link href="https://fonts.googleapis.com/css2?family=Philosopher&display=swap" rel="stylesheet" />
      </Helmet>
     
      
      <>
      <TopMenu></TopMenu>
        
            <main>{children}</main>
          
        <Footer></Footer>
      </>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
